import { useAppDispatch } from '@app/redux/hooks';
import {
  register,
  RegisterInterface,
  registerWithGoogle,
} from '@app/services/auth';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useSignUpPage = () => {
  const dispatch = useAppDispatch();
  const [signupError, setSignupError] = useState<string | undefined>();
  const signupForm = useForm();
  const navigate = useNavigate();

  const submitSignup = async (values: FieldValues) => {
    setSignupError(undefined);
    register(values as unknown as RegisterInterface)
      .then((res) => {
        dispatch({ type: 'auth/save', payload: res?.data?.user });
        navigate('/welcome');
      })
      .catch((err) => {
        setSignupError(err?.response?.data?.message);
      });
  };

  const submitGoogleSignup = () => {
    registerWithGoogle()
      .then((res) => console.log(res))
      .then((err) => console.error(err));
  };
  return { signupForm, submitSignup, signupError, submitGoogleSignup };
};
