import { InputHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import NoPhoto from '@app/assets/images/no-photo.svg';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClass?: string;
}
export const PhotoField = ({
  name,
  label,
  containerClass,
  ...rest
}: InputFieldProps) => {
  const {
    register,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [preview, setPreview] = useState<string | undefined>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  return (
    <div
      className={`w-full relative flex gap-2 items-center  ${containerClass ?? ''}`}
    >
      <div className="h-24 max-w-24 w-full rounded-full overflow-hidden relative">
        <img
          src={preview ?? NoPhoto}
          className="w-full h-full absolute object-cover object-center"
        />
      </div>
      <input
        {...register(name ?? 'photo-field')}
        type="file"
        onChange={onSelectFile}
        className={`bg-base-seasalt rounded py-3 px-4 h-fit w-full outline-brand-baseBlue outline-1 ${rest.className ?? ''}`}
      />
    </div>
  );
};
