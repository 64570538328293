import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { SelectHTMLAttributes, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ChevronDownIcon from '@app/assets/icons/chevron-down.svg?react';

interface SelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  placeholder: string;
  options: { value: string; label: string }[];
  showDefaultOption?: boolean;
}

export const SelectField = ({
  name,
  placeholder,
  options,
  showDefaultOption = false,
  ...rest
}: SelectFieldProps) => {
  const {
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const [query, setQuery] = useState('');

  return (
    <Controller
      control={control}
      name={name ?? ''}
      render={({ field: { onChange, value } }) => {
        return (
          <Combobox
            immediate
            value={value}
            onChange={onChange}
            onClose={() => setQuery('')}
          >
            {({ activeOption }) => (
              <>
                <div className="relative w-full">
                  <ComboboxInput
                    displayValue={
                      value
                        ? options.find((o) => o.value === value)?.label
                        : activeOption?.label
                    }
                    onChange={(event) => setQuery(event.target.value ?? '')}
                    placeholder={placeholder}
                    className={`group bg-base-seasalt rounded py-3 px-4 w-full outline-brand-baseBlue outline-1 ${rest.className ?? ''} `}
                  />
                  <ChevronDownIcon className="absolute translate-y-[-50%] top-[50%] right-2 group-data-[open]:opacity-0" />
                </div>
                <ComboboxOptions
                  anchor="bottom"
                  className="w-[--input-width] rounded-lg shadow-md bg-white mt-2"
                >
                  {showDefaultOption && (
                    <option
                      value="null"
                      disabled
                      className="data-[focus]:bg-blue-100 p-2"
                    >
                      Choose an option
                    </option>
                  )}
                  {query.length > 0 &&
                    options
                      .filter((option) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(query.toLowerCase())
                      )
                      .map((option) => (
                        <ComboboxOption
                          key={option.value}
                          value={option.value}
                          className="data-[focus]:bg-blue-100 p-2 border-b"
                        >
                          {option.label}
                        </ComboboxOption>
                      ))}
                  {(!query || query.length === 0) &&
                    options.map((option) => (
                      <ComboboxOption
                        key={option.value}
                        value={option.value}
                        className="data-[focus]:bg-blue-100 p-2 border-b"
                      >
                        {option.label}
                      </ComboboxOption>
                    ))}
                </ComboboxOptions>
              </>
            )}
          </Combobox>
        );
      }}
    />
  );
};
