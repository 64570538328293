import { Button } from '@app/components';
import { motion } from 'framer-motion';
import {
  MembershipTypes,
  planNames,
  plansAndBenefits,
} from '@app/utils/consts';
import { formatPrice } from '@app/utils/utils';
import DocSearchIcon from '@app/assets/icons/doc-search.svg?react';
import IphoneIcon from '@app/assets/icons/iphone.svg?react';
import RefreshIcon from '@app/assets/icons/refresh.svg?react';
import CheckIcon from '@app/assets/icons/check.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import DiamondIcon from '@app/assets/icons/diamond.svg?react';

export const SettingsPlan = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="container mx-auto p-4"
    >
      <div className="container mx-auto p-4">
        <div className="bg-white p-4 h-fit max-w-[50%]">
          <div className="flex items-start justify-between">
            <h5 className="font-bold text-sm">Your current plan</h5>
            <button className="bg-base-antiFlash p-2 rounded-lg flex gap-2 items-center text-sm">
              <span>View more</span>
              <DocSearchIcon />
            </button>
          </div>
          <hr className="my-4 border-base-ghostWhite" />
          <div className="w-full h-40 flex justify-between flex-col bg-gradient-to-r from-gradient-baseBlue from-[-15%]  to-gradient-ufoGreen to-[113%] rounded-xl text-white p-4">
            <p className="text-xs font-extrabold flex gap-2 items-center">
              <DiamondIcon /> <span>Professional Plan</span>
            </p>
            <p className="font-bold text-4xl text-brand-lightBlue4">$97</p>
            <p className="text-sm font-medium">Price per month</p>
          </div>
          <table className="my-4">
            <tbody>
              <tr>
                <td className="uppercase flex items-center gap-2 py-2 w-[160px]">
                  <span className="bg-base-antiFlash p-1 rounded-md">
                    <IphoneIcon />
                  </span>
                  <span className="text-base-coolGray font-medium text-xs">
                    discount name
                  </span>
                </td>
                <td>
                  <span className="font-medium text-sm">
                    Free trial - 12 days left
                  </span>
                </td>
              </tr>
              <tr>
                <td className="uppercase flex items-center gap-2 py-2 w-[160px]">
                  <span className="bg-base-antiFlash p-1 rounded-md">
                    <RefreshIcon />
                  </span>
                  <span className="text-base-coolGray font-medium text-xs">
                    next invoice
                  </span>
                </td>
                <td>
                  <span className="font-medium text-sm">13 June, 2023</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr className="my-6" />
        <div className="flex justify-end">
          <div className="flex gap-4">
            {planNames
              .filter((p) => p.name !== MembershipTypes.Trial)
              .map((plan) => (
                <div
                  key={plan.name}
                  className="bg-white w-fit p-4 flex flex-col rounded-t-xl "
                >
                  <h4
                    className={`text-sm font-semibold flex gap-2 ${plan.class}`}
                  >
                    <plan.Icon />
                    {plan.name}
                  </h4>
                  <hr className="my-2" />
                  <div className="space-y-4">
                    <p>
                      <span className="text-3xl font-extrabold">
                        {formatPrice(plan.price)}
                      </span>
                      <span className="font-medium text-lg text-base-coolGray">
                        /m
                      </span>
                    </p>
                    <p className="text-base-coolGray/60">Billed monthly</p>
                    <Button kind="secondary" className="font-medium text-sm">
                      Lower the plan
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <table className="bg-white w-full">
          <thead>
            <tr>
              <td
                colSpan={2}
                className="text-left border-b border-b-base-ghostWhite p-4 font-bold"
              >
                Usage
              </td>
            </tr>
          </thead>
          <tbody>
            {plansAndBenefits.map((benefit) => (
              <tr
                key={benefit.title}
                className="border-b border-b-base-ghostWhite "
              >
                <td className="p-4 text-brand-darkBlue2 font-medium text-sm">
                  <span>{benefit.title}</span>
                </td>
                {benefit.values.map((value, index) =>
                  value ? (
                    typeof value === 'boolean' ? (
                      <td
                        key={index}
                        align="right"
                        valign="middle"
                        className="inline-flex items-center justify-end flex-row p-4 w-[100px]"
                      >
                        <div>
                          <CheckIcon width={20} height={20} />
                        </div>
                      </td>
                    ) : (
                      <td
                        key={index}
                        align="right"
                        valign="middle"
                        className="inline-flex items-center justify-end flex-row p-4 w-[100px]"
                      >
                        <div>
                          <CheckIcon width={20} height={20} />
                        </div>{' '}
                        {value}
                      </td>
                    )
                  ) : (
                    <td
                      key={index}
                      align="right"
                      valign="middle"
                      className="inline-flex items-center justify-end flex-row p-4 w-[100px]"
                    >
                      <div>
                        <CloseIcon width={20} height={20} />
                      </div>
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};
