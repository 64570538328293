import DiamondIcon from '@app/assets/icons/diamond.svg?react';
import BriefcaseIcon from '@app/assets/icons/briefcase.svg?react';
import HourglassIcon from '@app/assets/icons/hourglass.svg?react';
import EnterpriseIcon from '@app/assets/icons/enterprise.svg?react';
import { isEqual } from 'lodash';

export const getMembershipInfo = (membership: string) => {
  switch (membership) {
    case 'Enterprise': {
      return {
        color: { start: '#52B6FE', end: '#F5B8FF' },
        name: 'Enterprise',
        description: 'This is the Enterprise membership',
        Icon: EnterpriseIcon,
      };
    }
    case 'Business': {
      return {
        color: { start: '#52B6FE', end: '#0094FF' },
        name: 'Business',
        description: 'This is the Business membership',
        Icon: BriefcaseIcon,
      };
    }
    case 'Professional': {
      return {
        color: { start: '#52B6FE', end: '#4ADA73' },
        name: 'Professional',
        description: 'This is the Professional membership',
        Icon: DiamondIcon,
      };
    }
    case 'Trial': {
      return {
        color: { start: '#CBE9FF', end: '#CBE9FF' },
        name: 'Trial',
        description: 'This is the Trial membership',
        Icon: HourglassIcon,
      };
    }
    default: {
      return {
        color: { start: '#52B6FE', end: '#F5B8FF' },
        name: 'Enterprise',
        description: 'This is the Enterprise membership',
        Icon: HourglassIcon,
      };
    }
  }
};

export const formatPrice = (price?: string | number, decimals?: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals ?? 0,
    maximumFractionDigits: decimals ?? 0,
  }).format(Number(price) ?? 0);
};

export const getObjectDiff = (
  obj1: { [x: string]: string | number | boolean },
  obj2: { [x: string]: string | number | boolean },
  compareRef = false
) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};
