import { Link } from 'react-router-dom';
import GoogleIcon from '@app/assets/icons/Google.svg?react';
import FacebookIcon from '@app/assets/icons/Facebook.svg?react';
import TrustpilotIcon from '@app/assets/icons/Trustpilot.svg?react';
import { Tooltip } from '../Tooltip/Tooltip';
import { getMembershipInfo } from '@app/utils/utils';

interface BusinessSelectProps {
  id: string;
  name: string;
  role: string;
  google: string;
  facebook: string;
  trustpilot: string;
  membership: string;
  color: string;
}

export const BusinessSelect = ({
  id,
  name,
  role,
  google,
  facebook,
  trustpilot,
  membership,
  color,
}: BusinessSelectProps) => {
  const membershipInfo = getMembershipInfo(membership);
  return (
    <div
      id={id}
      className="w-full bg-white rounded-lg shadow-md flex items-center justify-between p-2 gap-2"
    >
      <div>
        <div
          className="w-12 h-12 flex items-center justify-center font-bold text-2xl  rounded-lg"
          style={{ color: `#${color}`, background: `#${color}14` }}
        >
          A
        </div>
      </div>
      <div className="mr-auto">
        <h5 className="font-bold">{name}</h5>
        <p className="text-sm text-base-coolGray">{role}</p>
      </div>
      <div className="flex gap-1 ">
        <Link to={google}>
          <GoogleIcon width={16} height={16} />
        </Link>
        <Link to={facebook}>
          <FacebookIcon width={16} height={16} />
        </Link>
        <Link to={trustpilot}>
          <TrustpilotIcon width={16} height={16} />
        </Link>
      </div>
      <div>
        <Tooltip content={<p>{membershipInfo.description}</p>}>
          <div
            className="w-9 h-9 flex items-center justify-center font-bold text-2xl rounded-lg"
            style={{
              background: `linear-gradient(106.15deg, ${membershipInfo.color.start} -0.54%, ${membershipInfo.color.end} 100.37%)`,
            }}
          >
            <membershipInfo.Icon width={18} height={18} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
