import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth';
import businessReducer from './features/business';
// ...

export const store = configureStore({
  reducer: {
    auth: authReducer,
    business: businessReducer,
  },
});

// Infer the `RootState`,  `AppDispatch`, and `AppStore` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
