import { UserInterface } from '@app/models/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  user: UserInterface | undefined;
}

const initialState: AuthState = {
  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
    },
    update: (state, action: PayloadAction<UserInterface>) => {
      state.user = { ...state.user, ...action.payload };
    },
    logout: (state) => {
      state.user = undefined;
    },
  },
});

export const { save, update, logout } = authSlice.actions;

export default authSlice.reducer;
