import { ButtonHTMLAttributes } from "react";
import { getButtonByKind } from "./util";
import { Link } from "react-router-dom";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string;
  kind?: "primary" | "secondary" | "tertiary";
  size?: "default" | "small";
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
  to?: string;
}
export const Button = ({
  children,
  kind,
  size,
  leftNode,
  rightNode,
  to,
  ...rest
}: ButtonProps) => {
  const style = getButtonByKind(kind);

  if (to) {
    return (
      <Link to={to} className={`${style} ${rest.className ?? ""}`}>
        {leftNode && <span className="mr-2">{leftNode}</span>}
        {children}
        {rightNode && <span className="ml-2">{rightNode}</span>}
      </Link>
    );
  }
  return (
    <button {...rest} className={`${style} ${rest.className ?? ""}`}>
      {leftNode && <span className="mr-2">{leftNode}</span>}
      {children}
      {rightNode && <span className="ml-2">{rightNode}</span>}
    </button>
  );
};
