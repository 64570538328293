import { confirmReseting, resetPassword } from '@app/services/auth';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export const useNewPasswordPage = () => {
  const [resetPasswordEnabled, setResetPasswordEnabled] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [passwordChangeValid, setPasswordChangeValid] = useState(false);
  const params = useParams();
  const resetPasswordForm = useForm();
  const navigate = useNavigate();

  const submitNewPassword = (values?: FieldValues) => {
    if (values?.password && params?.token) {
      resetPassword(userEmail, values.password)
        .then(() => {
          setPasswordChangeValid(true);
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        })
        .catch(() => {
          setResetPasswordEnabled(false);
        });
    }
  };

  useEffect(() => {
    if (params?.token) {
      confirmReseting(params.token)
        .then((res) => {
          setUserEmail(res?.data?.email);
          setResetPasswordEnabled(true);
        })
        .catch(() => {
          setUserEmail('');
          setResetPasswordEnabled(false);
        });
    }
  }, []);

  return {
    resetPasswordEnabled,
    resetPasswordForm,
    submitNewPassword,
    userEmail,
    passwordChangeValid,
    navigate,
  };
};
