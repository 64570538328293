import { Button, HookedForm, InputField } from '@app/components';
import { Link } from 'react-router-dom';
import Logo from '@app/assets/horizontal-logo.svg?react';
import GoogleLogo from '@app/assets/icons/Google.svg?react';
import { useLoginPage } from './Login.hooks';

export const Login = () => {
  const { loginForm, submitLogin, loginError, submitGoogleLogin } =
    useLoginPage();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6">
        <h2 className="font-bold text-3xl">Welcome back!</h2>
        <p className="text-base-coolGray ">
          Enter the information below to log in to your account
        </p>
        {loginError && (
          <div className="bg-system-errorLight p-2 rounded-lg text-system-error font-medium ">
            <p>{loginError}</p>
          </div>
        )}

        <HookedForm
          formHook={loginForm}
          onSubmit={submitLogin}
          className="flex gap-2 flex-col"
        >
          <InputField name="email" type="email" placeholder="Email" />
          <p className="text-right">
            <Link to="/forgot-password" className="text-brand-baseBlue">
              Forgot password?
            </Link>
          </p>
          <InputField name="password" type="password" placeholder="Password" />
          <div className="flex items-center justify-center flex-col mt-2">
            <Button type="submit" className="w-full">
              Log in
            </Button>
            <p className="text-base-frenchGray">or</p>
            <Button
              onClick={() => submitGoogleLogin()}
              type="button"
              kind="tertiary"
              className="border w-full"
              leftNode={<GoogleLogo />}
            >
              Log in with Google
            </Button>
          </div>
          <p className="text-base-frenchGray text-xs">
            By logging in, you agree to our Terms of Service and Privacy Policy
            .
          </p>
        </HookedForm>
      </div>
      <p className="mt-auto text-base-coolGray">
        Don’t have a business account?{' '}
        <Link to="/sign-up" className="text-brand-baseBlue">
          Create one
        </Link>
      </p>
    </div>
  );
};
