const baseStyles =
  "min-w-[200px] flex items-center justify-center gap-2 font-bold p-2 rounded-lg";

export const getButtonByKind = (kind?: string, size?: string) => {
  switch (kind) {
    default:
    case "primary": {
      return `${baseStyles} bg-brand-baseBlue text-white 
        ${size === "small" ? " text-sm" : ""}`;
    }
    case "secondary": {
      return `${baseStyles} bg-base-ghostWhite text-base-blue 
        ${size === "small" ? " text-sm" : ""}`;
    }
    case "tertiary": {
      return `${baseStyles} bg-transparent text-base-blue 
        ${size === "small" ? " text-sm" : ""}`;
    }
  }
};
