import { AnimationLayout, AuthGuard, DashboardLayout } from '@app/components';
import {
  ForgotPassword,
  Login,
  NewBusiness,
  NewPassword,
  ProfileSettings,
  SelectBusiness,
  Settings,
  SignUp,
  ValidateEmail,
  Welcome,
} from '@app/pages';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AnimationLayout />,
    children: [
      {
        path: '/login',
        element: <Login />,
        handle: {
          crumb: () => 'Login',
        },
      },
      {
        path: '/validate-email/:token',
        element: <ValidateEmail />,
        handle: {
          crumb: () => 'Validate Email',
        },
      },
      {
        path: '/sign-up',
        element: <SignUp />,
        handle: {
          crumb: () => 'Sign Up',
        },
      },
      {
        path: '/welcome',
        element: <Welcome />,
        handle: {
          crumb: () => 'Welcome',
        },
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
        handle: {
          crumb: () => 'Forgot Password',
        },
      },
      {
        path: '/reset-password/:token',
        element: <NewPassword />,
        handle: {
          crumb: () => 'Reset Password',
        },
      },

      {
        element: <AuthGuard />,
        children: [
          {
            path: '/select-business',
            element: <SelectBusiness />,
            handle: {
              crumb: () => 'Select Business',
            },
          },
          {
            path: '/select-business/create-new',
            element: <NewBusiness />,
            handle: {
              crumb: () => 'Create new Business',
            },
          },
          {
            element: <DashboardLayout />,
            children: [
              {
                index: true,
                path: '/',
                element: <div>Dashboard</div>,
                handle: {
                  crumb: () => 'Dashboard',
                },
              },
              {
                path: '/settings',
                element: <Settings />,
                handle: {
                  crumb: () => 'Settings',
                },
              },
              {
                path: '/profile',
                element: <ProfileSettings />,
                handle: {
                  crumb: () => 'Profile Settings',
                },
              },
            ],
          },
        ],
      },
    ],
  },
]);
