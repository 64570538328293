import { Button, HookedForm, InputField } from '@app/components';
import { Link } from 'react-router-dom';
import Logo from '@app/assets/horizontal-logo.svg?react';
import BackIcon from '@app/assets/icons/back.svg?react';
import LockPassword from '@app/assets/images/lock-password.svg?react';
import { useForgotPasswordPage } from './ForgotPassword.hooks';

export const ForgotPassword = () => {
  const {
    submitForgotPassword,
    emailSent,
    forgotPassordForm,
    navigate,
    userEmail,
  } = useForgotPasswordPage();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6">
        <div>
          <Button
            kind="tertiary"
            className="min-w-fit !w-fit"
            leftNode={<BackIcon />}
            onClick={() => navigate('/login')}
          />
        </div>
        {emailSent ? (
          <>
            <div className="flex items-center justify-center">
              <LockPassword width={140} height={140} />
            </div>
            <h2 className="font-bold text-3xl">Password reset link sent to</h2>
            <p className="text-brand-darkBlue2">{userEmail}</p>
            <p className="text-base-coolGray">
              If you don&apos;t see it in a couple of minutes, check you entered
              the correct email and check your spam folder.
            </p>
            <Button kind="tertiary" className="w-full text-brand-darkBlue2">
              Resend link
            </Button>
            <Button className="w-full">Return to Log In</Button>
          </>
        ) : (
          <>
            <h2 className="font-bold text-3xl">Forgot your password?</h2>
            <p className="text-base-coolGray ">
              Enter the email associated with your account, and we&apos;ll send
              you a link to reset your password.
            </p>

            <HookedForm
              formHook={forgotPassordForm}
              onSubmit={submitForgotPassword}
              className="flex gap-2 flex-col"
            >
              <InputField name="email" type="email" placeholder="Email" />

              <div className="flex items-center justify-center flex-col mt-2">
                <Button className="w-full">Send reset link</Button>
              </div>
              <p className="text-base-frenchGray text-xs">
                By continuing, you agree to our Terms of Service and Privacy
                Policy .
              </p>
            </HookedForm>
          </>
        )}
      </div>
      <p className="mt-auto text-base-coolGray">
        Don’t have a business account?{' '}
        <Link to="/sign-up" className="text-brand-baseBlue">
          Create one
        </Link>
      </p>
    </div>
  );
};
