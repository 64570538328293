import { useAppSelector } from '@app/redux/hooks';
// import { update } from '@app/services/user';
import { getObjectDiff } from '@app/utils/utils';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

const fieldsToIgnore = [
  'firstName',
  'lastName',
  'businessAddress',
  'businessCategory',
  'businessName',
  'email',
  'isActivated',
  'id',
];

export const useProfileSettingsPage = () => {
  const authState = useAppSelector((state) => state.auth);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [changePasswordSent, setChangePasswordSent] = useState(false);
  const [changeInformationOpen, setChangeInformationOpen] = useState(false);
  const updateProfileForm = useForm();

  const handleGotIt = () => {
    setChangePasswordOpen(false);
    setChangePasswordSent(false);
  };

  const handleChangeInformation = () => {
    setChangeInformationOpen(!changeInformationOpen);
  };

  const handleUpdateForm = (values: FieldValues) => {
    const originalData = { ...authState?.user };
    const newName = values.firstName + ' ' + values.lastName;

    const differentKeys = getObjectDiff(
      { ...values, name: newName },
      originalData
    ).filter((key) => !fieldsToIgnore.includes(key));

    if (differentKeys?.length) {
      const newData = differentKeys.map((key) => {
        if (key === 'name') {
          return {
            name: values.firstName + ' ' + values.lastName,
          };
        } else {
          return { [key]: values[key] };
        }
      });

      const data = Object.assign({}, ...newData);
      console.log(data);
    }
  };

  return {
    changeInformationOpen,
    handleChangeInformation,
    changePasswordOpen,
    changePasswordSent,
    handleGotIt,
    setChangePasswordOpen,
    setChangePasswordSent,
    authState,
    updateProfileForm,
    handleUpdateForm,
  };
};
