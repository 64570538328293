import { Button, HookedForm, InputField } from '@app/components';
import { Link } from 'react-router-dom';
import Logo from '@app/assets/horizontal-logo.svg?react';
import { useNewPasswordPage } from './NewPassword.hooks';

export const NewPassword = () => {
  const {
    resetPasswordEnabled,
    resetPasswordForm,
    submitNewPassword,
    userEmail,
    passwordChangeValid,
    navigate,
  } = useNewPasswordPage();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6">
        <h2 className="font-bold text-3xl">Reset password</h2>
        <p className="text-base-coolGray">{userEmail}</p>
        {!passwordChangeValid && (
          <HookedForm
            formHook={resetPasswordForm}
            onSubmit={submitNewPassword}
            className="flex gap-2 flex-col"
          >
            <InputField
              name="password"
              type="password"
              placeholder="New password"
            />
            <InputField
              name="confirmPassword"
              type="password"
              placeholder="Confirm password"
            />
            <div className="flex items-center justify-center flex-col mt-2">
              <Button
                type="submit"
                disabled={!resetPasswordEnabled}
                className="w-full"
              >
                Reset password
              </Button>
            </div>
            <p className="text-base-frenchGray text-xs">
              By continuing, you agree to our Terms of Service and Privacy
              Policy .
            </p>
          </HookedForm>
        )}
        {passwordChangeValid && (
          <>
            <p>
              Your password was successfuly changed, redirecting to in 5
              seconds.
            </p>
            <Button
              type="button"
              onClick={() => navigate('/login')}
              className="w-full"
            >
              Go to login
            </Button>
          </>
        )}
      </div>
      <p className="mt-auto text-base-coolGray">
        Don&apos;t have a business account?{' '}
        <Link to="/sign-up" className="text-brand-baseBlue">
          Create one
        </Link>
      </p>
    </div>
  );
};
