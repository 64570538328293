import {
  BaseDialog,
  Button,
  HookedForm,
  InputField,
  PhoneField,
} from '@app/components';
import NoPhoto from '@app/assets/images/no-photo.svg?react';
import PencilIcon from '@app/assets/icons/pencil.svg?react';
import PasswordChangeIcon from '@app/assets/icons/password-change.svg?react';
import LockPassword from '@app/assets/images/lock-password.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { PhotoField } from '@app/components/Fields/PhotoField';
import { useProfileSettingsPage } from './ProfileSettings.hooks';

export const ProfileSettings = () => {
  const {
    changeInformationOpen,
    changePasswordOpen,
    changePasswordSent,
    handleChangeInformation,
    handleGotIt,
    setChangePasswordOpen,
    setChangePasswordSent,
    authState,
    updateProfileForm,
    handleUpdateForm,
  } = useProfileSettingsPage();

  const userFullName = authState?.user?.name.split(' ');

  return (
    <>
      <div>
        <div className="bg-white border-b border-b-base-ghostWhite ">
          <div className="container mx-auto p-4">
            <h2 className="font-bold text-[28px]">Profile Settings</h2>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 container mx-auto p-4">
          <div className="bg-white p-4 h-fit">
            <div className="flex items-center justify-between">
              <h5 className="font-bold text-sm">Main Information</h5>
              <button
                onClick={handleChangeInformation}
                className="bg-base-antiFlash p-2 rounded-lg"
              >
                <PencilIcon />
              </button>
            </div>
            <hr className="my-4" />
            <div className="space-y-4">
              <NoPhoto />
              <table>
                <tbody>
                  <tr>
                    <td className="w-[130px]">
                      <span className="text-xs text-base-coolGray">
                        FIRST NAME
                      </span>
                    </td>
                    <td>
                      <span className="text-sm font-medium">
                        {userFullName?.[0]}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-[130px]">
                      <span className="text-xs text-base-coolGray">
                        LAST NAME
                      </span>
                    </td>
                    <td>
                      <span className="text-sm font-medium">
                        {userFullName
                          ?.slice(1, userFullName?.length)
                          ?.join(' ')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-[130px]">
                      <span className="text-xs text-base-coolGray">
                        JOB TITLE
                      </span>
                    </td>
                    <td>
                      <span className="text-sm font-medium">Admin</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="bg-white p-4">
            <h5 className="font-bold text-sm py-2">Account Contacts</h5>
            <hr className="my-4" />
            <HookedForm className="space-y-4">
              <InputField
                name="email"
                label="Email"
                defaultValue={authState?.user?.email}
              />
              <div className="flex justify-end">
                <Button>Verify email address</Button>
              </div>
              <PhoneField
                name="phoneNumber"
                label="Phone number"
                defaultValue="+1 234 56 78 91"
              />
              <div className="flex justify-end">
                <Button>Verify phone number</Button>
              </div>
            </HookedForm>
          </div>
          <div className="md:col-start-2 bg-white p-4">
            <h5 className="font-bold text-sm py-2">Password</h5>
            <hr className="my-4" />
            <div className="space-y-4">
              <table>
                <tbody>
                  <tr>
                    <td className="w-[130px]">
                      <span className="text-xs text-base-coolGray">
                        PASSWORD
                      </span>
                    </td>
                    <td>
                      <span className="text-sm font-medium">*************</span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span className="text-sm text-base-coolGray">
                        Your password was last changed 5 days ago
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex justify-end">
                <Button
                  kind="secondary"
                  onClick={() => setChangePasswordOpen(true)}
                >
                  Change password
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseDialog
        open={changePasswordOpen}
        onClose={() =>
          changePasswordSent
            ? handleGotIt()
            : setChangePasswordOpen(!changePasswordOpen)
        }
      >
        {!changePasswordSent ? (
          <>
            <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
              <div></div>
              <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
                <PasswordChangeIcon width={24} height={24} />
              </div>
              <div className="mr-auto">
                <h5 className="font-bold text-lg">Change password</h5>
                <p className="text-base-coolGray text-sm">
                  Enter your email below to receive
                  <br />a password reset email
                </p>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setChangePasswordOpen(!changePasswordOpen)}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div className="px-6 pb-4">
              <HookedForm className="space-y-4">
                <InputField name="email" label="Email" />
                <p className="text-xs text-base-coolGray">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>

                <div className="flex items-center gap-4 justify-center ">
                  <Button
                    kind="secondary"
                    className="w-full"
                    onClick={() => setChangePasswordOpen(!changePasswordOpen)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-full "
                    onClick={() => setChangePasswordSent(!changePasswordSent)}
                  >
                    Send Email
                  </Button>
                </div>
              </HookedForm>
            </div>
          </>
        ) : (
          <div className="space-y-4 p-6 text-center flex items-center flex-col">
            <div className="flex items-center justify-center">
              <LockPassword width={140} height={140} />
            </div>
            <h2 className="font-bold text-3xl">Password reset link sent to</h2>
            <p className="text-brand-darkBlue2 font-bold">
              {authState?.user?.email}
            </p>
            <p className="text-base-coolGray text-sm">
              If you don&apos;t see it in a couple of minutes, check you entered
              the correct email and check your spam folder.
            </p>
            <Button className="w-fit" onClick={() => handleGotIt()}>
              Got It
            </Button>
          </div>
        )}
      </BaseDialog>
      <BaseDialog
        open={changeInformationOpen}
        onClose={handleChangeInformation}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div></div>
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <PencilIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Change information</h5>
            <p className="text-base-coolGray text-sm">
              Update your user information
            </p>
          </div>
          <div>
            <button type="button" onClick={handleChangeInformation}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <HookedForm
            formHook={updateProfileForm}
            onSubmit={handleUpdateForm}
            className="space-y-4"
          >
            <PhotoField name="photo" label="Photo" />
            <InputField
              name="firstName"
              label="First name"
              defaultValue={userFullName?.[0]}
            />
            <InputField
              name="lastName"
              label="Last name"
              defaultValue={userFullName
                ?.slice(1, userFullName?.length)
                ?.join(' ')}
            />
            <InputField name="jobTitle" label="Job Title" />

            <div className="flex items-center gap-4 justify-center ">
              <Button
                kind="secondary"
                className="w-full"
                onClick={handleChangeInformation}
              >
                Cancel
              </Button>
              <Button className="w-full " onClick={handleChangeInformation}>
                Update Information
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseDialog>
    </>
  );
};
