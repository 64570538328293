import HourglassIcon from '@app/assets/icons/hourglass.svg';
import DiamondIcon from '@app/assets/icons/diamond.svg?react';
import BriefcaseIcon from '@app/assets/icons/briefcase.svg?react';
import EnterpriseIcon from '@app/assets/icons/enterprise.svg?react';
import AnalyticsIcon from '@app/assets/icons/menu/analytics.svg?react';
import AutomationsIcon from '@app/assets/icons/menu/automations.svg?react';
import CampaignsIcon from '@app/assets/icons/menu/campaigns.svg?react';
import CustomersIcon from '@app/assets/icons/menu/customers.svg?react';
import RatingsIcon from '@app/assets/icons/menu/ratings.svg?react';
import IntegrationsIcon from '@app/assets/icons/menu/integrations.svg?react';
import DashboardIcon from '@app/assets/icons/menu/dashboard.svg?react';
import OnboardingIcon from '@app/assets/icons/menu/onboarding.svg?react';

export const MembershipTypes = {
  Enterprise: 'Enterprise',
  Business: 'Business',
  Professional: 'Professional',
  Trial: 'Trial',
};

export const SettingsTabs = [
  { label: 'Business details', value: 1 },
  { label: 'Team members', value: 2 },
  { label: 'Payment methods', value: 3 },
  { label: 'Plan', value: 4 },
];

export const planNames = [
  {
    name: MembershipTypes.Trial,
    price: '0',
    Icon: HourglassIcon,
    class: 'blue-to-pink',
  },
  {
    name: MembershipTypes.Business,
    price: 67,
    Icon: BriefcaseIcon,
    class: 'blue-to-pink',
  },
  {
    name: MembershipTypes.Professional,
    price: 97,
    Icon: DiamondIcon,
    class: 'blue-to-green',
  },
  {
    name: MembershipTypes.Enterprise,
    price: 147,
    Icon: EnterpriseIcon,
    class: 'blue-to-dark-blue',
  },
];

export const plansAndBenefits = [
  { title: 'Review Requests', values: [500, 1500, 10000] },
  { title: 'Member seats', values: [3, 10, 50] },
  {
    title: 'Connections with leading review websites',
    values: [true, true, true],
  },
  {
    title: 'Automatic review follow-up via email/text',
    values: [true, true, true],
  },
  { title: 'Embeddable widgets for your website', values: [true, true, true] },
  { title: 'Industry-leading support', values: [true, true, true] },
  { title: 'Bulk and manual customer import', values: [true, true, true] },
  {
    title: 'AI-Assisted Reviews for stronger feedback',
    values: [false, true, true],
  },
  {
    title: 'AI-Assisted email follow-up and customization',
    values: [false, true, true],
  },
  { title: 'CRM Integration for Customers', values: [false, true, true] },
  {
    title: 'In-depth, structured analytics for detailed review monitoring',
    values: [false, true, true],
  },
  {
    title: '24/7 support line for Enterprise customers',
    values: [false, false, true],
  },
];

export const sideDrawerItems = [
  {
    label: 'Onboarding',
    to: '/',
    Icon: OnboardingIcon,
  },
  {
    label: 'Dashboard',
    to: '/',
    Icon: DashboardIcon,
  },
  {
    label: 'Ratings',
    to: '/',
    Icon: RatingsIcon,
  },
  {
    label: 'Customers',
    to: '/',
    Icon: CustomersIcon,
  },
  {
    label: 'Analytics',
    to: '/',
    Icon: AnalyticsIcon,
  },
  {
    label: 'Campaigns',
    to: '/',
    Icon: CampaignsIcon,
  },
  {
    label: 'Automations',
    to: '/',
    Icon: AutomationsIcon,
  },
  {
    label: 'Integrations',
    to: '/',
    Icon: IntegrationsIcon,
  },
];
