import { Button, HookedForm, InputField, SelectField } from "@app/components";
import { useNavigate } from "react-router-dom";
import Logo from "@app/assets/horizontal-logo.svg?react";
import BackIcon from "@app/assets/icons/back.svg?react";

export const NewBusiness = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6 mb-auto">
        <div>
          <Button
            kind="tertiary"
            className="min-w-fit !w-fit"
            leftNode={<BackIcon />}
            onClick={() => navigate("/select-business")}
          />
        </div>
        <h2 className="font-bold text-3xl">New business account</h2>
        <p className="text-base-coolGray">
          Just a few details needed to set up your account
        </p>

        <HookedForm className="flex gap-2 flex-col">
          <InputField
            name="businessName"
            type="text"
            placeholder="Business name"
          />
          <InputField
            name="businessAddress"
            type="text"
            placeholder="Business address"
          />
          <SelectField
            name="companyType"
            placeholder="Company type"
            showDefaultOption
            options={[]}
          />
          <div className="flex items-center justify-center flex-col mt-2">
            <Button className="w-full">Start 14-days free trial</Button>
          </div>
          <p className="text-base-frenchGray text-xs">
            By continuing, you agree to our Terms of Service and Privacy Policy
            .
          </p>
        </HookedForm>
      </div>
    </div>
  );
};
