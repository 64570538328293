import { Link } from 'react-router-dom';
import HorizontalLogo from '@app/assets/horizontal-logo.svg?react';
import SettingsIcon from '@app/assets/icons/menu/settings.svg?react';
import { useAppSelector } from '@app/redux/hooks';
import { sideDrawerItems } from '@app/utils/consts';

export const SideDrawer = () => {
  const authState = useAppSelector((state) => state.auth);

  return (
    <div className="bg-white h-full  flex flex-col items-start border-r">
      <div className="border-b border-b-base-ghostWhite p-4 w-full">
        <HorizontalLogo width={126} height={56} />
      </div>
      <div className="border-b border-b-base-ghostWhite p-4 w-full">
        <div className="flex items-center gap-2">
          <div
            className="w-8 h-8 flex items-center justify-center font-bold text-lg rounded-lg"
            style={{ color: `#424244`, background: `#42424414` }}
          >
            {authState?.user?.businessName?.charAt(0)}
          </div>
          <h5 className="font-bold text-sm">{authState?.user?.businessName}</h5>
        </div>
      </div>
      <div className="p-4 w-full relative">
        <ul className="flex gap-4 flex-col text-sm">
          {sideDrawerItems.map((item) => (
            <li>
              <Link
                className="flex gap-2 mb-2 items-center hover:bg-brand-lightBlue4 p-1 rounded-lg"
                to={item.to}
              >
                <item.Icon /> {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-auto p-4">
        <div className="bg-system-successLight p-2 rounded-lg mb-2">
          <h5 className="text-system-successDark text-sm font-bold mb-1">
            Leave feedback
          </h5>
          <p className="text-xs text-base-davyGray">
            Leave feedback on our platform so we can make it better
          </p>
        </div>
        <div>
          <Link
            className="flex gap-2 mb-2 items-center hover:bg-brand-lightBlue4 p-1 rounded-lg"
            to="/settings"
          >
            <SettingsIcon />
            Settings
          </Link>
        </div>
      </div>
    </div>
  );
};
