import { Tabs } from '@app/components';
import { SettingsTabs } from '@app/utils/consts';
import { useSettingsPage } from './Settings.hooks';
import { useMemo } from 'react';
import { SettingsPlan } from './Subpages';
import { motion, AnimatePresence } from 'framer-motion';

export const Settings = () => {
  const { activeSubpage, handleTabChange } = useSettingsPage();

  const render = useMemo(() => {
    switch (activeSubpage) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4">Business details</div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4">Team members</div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4">Payment methods</div>
          </motion.div>
        );
      case 4:
        return <SettingsPlan />;
      default:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container mx-auto p-4"
          >
            <div className="bg-white p-4">Payment methods</div>
          </motion.div>
        );
    }
  }, [activeSubpage]);
  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto pt-4 px-4">
          <h2 className="font-bold text-[28px] mb-2">Settings</h2>
          <Tabs tabs={SettingsTabs} onClick={handleTabChange} />
        </div>
      </div>
      <AnimatePresence initial={false}>{render}</AnimatePresence>
    </div>
  );
};
