import clsx from 'clsx';
import { useState } from 'react';

export const Tabs = ({
  tabs,
  onClick,
}: {
  tabs: { label: string; value: number }[];
  onClick?: (value: number) => void;
}) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div>
      <ul className="flex gap-2">
        {tabs.map((tab) => (
          <li
            key={tab.value}
            onClick={() => {
              setActiveTab(tab.value);
              onClick && onClick(tab.value);
            }}
            className={clsx(
              'text-base-coolGray text-sm font-medium py-2 px-4 cursor-pointer',
              {
                'border-b-2 border-b-base-raisinBlack text-base-raisinBlack':
                  activeTab === tab.value,
              }
            )}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
