import { InputHTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EyeIcon from '@app/assets/icons/eye.svg?react';
import EyeClosedIcon from '@app/assets/icons/close-eye.svg?react';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClass?: string;
}
export const InputField = ({
  name,
  label,
  containerClass,
  ...rest
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <div className={`w-full relative ${containerClass ?? ''}`}>
      {label && (
        <label htmlFor={name} className="font-medium text-sm inline-block mb-1">
          {label}
        </label>
      )}
      <input
        {...rest}
        type={
          rest.type === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : rest.type
        }
        {...register(name ?? '')}
        className={`bg-base-seasalt rounded py-3 px-4 w-full outline-brand-baseBlue outline-1 ${rest.className ?? ''}`}
      />
      {rest.type === 'password' && (
        <button
          type="button"
          className="absolute translate-y-[-50%] top-[50%] right-2"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {!showPassword ? (
            <EyeIcon width={24} height={24} />
          ) : (
            <EyeClosedIcon width={24} height={24} />
          )}
        </button>
      )}
    </div>
  );
};
