import { useAppDispatch } from '@app/redux/hooks';
import { login, loginWithGoogle } from '@app/services/auth';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useLoginPage = () => {
  const dispatch = useAppDispatch();
  const [loginError, setLoginError] = useState<string | undefined>();
  const loginForm = useForm();
  const navigate = useNavigate();

  const submitLogin = async (values: FieldValues) => {
    setLoginError(undefined);
    login(values.email, values.password)
      .then((res) => {
        dispatch({ type: 'auth/save', payload: res?.data?.user });
        localStorage.setItem('accessToken', res?.data?.accessToken);
        navigate('/');
      })
      .catch((err) => {
        setLoginError(err?.response?.data?.message);
      });
  };

  const submitGoogleLogin = () => {
    loginWithGoogle()
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };
  return { loginForm, submitLogin, loginError, submitGoogleLogin };
};
