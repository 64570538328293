import { verifyPasswordEmail } from '@app/services/auth';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useForgotPasswordPage = () => {
  const forgotPassordForm = useForm();
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleSendReset = () => {
    setEmailSent(true);
  };

  const submitForgotPassword = async (values: FieldValues) => {
    verifyPasswordEmail(values.email)
      .then(() => handleSendReset())
      .catch((err) => console.error(err));
  };

  const userEmail = forgotPassordForm.watch('email');
  return {
    forgotPassordForm,
    submitForgotPassword,
    emailSent,
    navigate,
    userEmail,
  };
};
