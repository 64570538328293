import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BusinessState {
  business: { id: string; name: string }[];
}

const initialState: BusinessState = {
  business: [],
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    addBusiness: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      if (state.business.some((bus) => bus.id === action.payload.id)) {
        return;
      } else {
        state.business.push(action.payload);
      }
    },
    updateBusiness: (
      state,
      action: PayloadAction<{ id: string; data: { name: string } }>
    ) => {
      if (state.business.some((bus) => bus.id === action.payload.id)) {
        state.business = state.business.map((bus) =>
          bus.id === action.payload.id
            ? { ...bus, ...action.payload.data }
            : bus
        );
      }
    },
    deleteBusiness: (state, action: PayloadAction<{ id: string }>) => {
      state.business = state.business.filter(
        (bus) => bus.id !== action.payload.id
      );
    },
  },
});

export const { addBusiness, updateBusiness, deleteBusiness } =
  businessSlice.actions;

export default businessSlice.reducer;
