import { Outlet, UIMatch, useMatches } from 'react-router-dom';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import { useEffect, useState } from 'react';
import UsageIcon from '@app/assets/icons/menu/usage.svg?react';
import NotificationIcon from '@app/assets/icons/menu/notification.svg?react';
import NotificationUnreadIcon from '@app/assets/icons/menu/notification-unread.svg?react';
import { UserMenu } from '../UserMenu/UserMenu';

type HandleType = {
  crumb: (param?: string) => React.ReactNode;
};

export const DashboardLayout = () => {
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const matches: UIMatch[] = useMatches();
  let crumbs = matches

    .filter((match) =>
      Boolean(match.handle && (match.handle as HandleType).crumb)
    )

    .map((match) =>
      (match.handle as HandleType).crumb(match.data as string | undefined)
    );

  useEffect(() => {
    setTimeout(() => {
      setUnreadNotifications(20);
    }, 900);
  }, []);

  return (
    <section className="flex min-h-screen">
      <div className="w-56">
        <SideDrawer />
      </div>
      <div className="w-full">
        <div className="bg-white text-sm border-b">
          <div className="container h-12 p-4 mx-auto flex items-center justify-between font-medium">
            <h4>{crumbs?.[0]}</h4>
            <div className="flex gap-4 items-center">
              <button className="flex items-center">
                <UsageIcon />
                Usage and Plan
              </button>
              <div>
                {unreadNotifications === 0 ? (
                  <NotificationIcon width={18} height={19} />
                ) : (
                  <NotificationUnreadIcon width={28} height={30} />
                )}
              </div>
              <div>
                <UserMenu />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </section>
  );
};
