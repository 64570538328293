import { Button, HookedForm, InputField, SelectField } from '@app/components';
import { Link } from 'react-router-dom';
import { useSignUpPage } from './SignUp.hooks';
import GoogleLogo from '@app/assets/icons/Google.svg?react';
import Logo from '@app/assets/horizontal-logo.svg?react';

export const SignUp = () => {
  const { signupError, signupForm, submitSignup, submitGoogleSignup } =
    useSignUpPage();
  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} className="mb-auto" />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6">
        <h2 className="font-bold text-3xl">Manage reviews effectively</h2>
        <p className="text-base-coolGray ">
          Effectively manage and respond to reviews while enjoying analytics,
          create and automate review requests, and use artificial intelligence
          for personalized emails or SMS.
        </p>
        {signupError && (
          <div className="bg-system-errorLight p-2 rounded-lg text-system-error font-medium ">
            <p>{signupError}</p>
          </div>
        )}
        <HookedForm
          formHook={signupForm}
          onSubmit={submitSignup}
          className="flex gap-4 flex-col"
        >
          <div className="flex gap-4">
            <InputField
              name="name"
              placeholder="Full name"
              className="w-full"
            />
            <InputField
              name="email"
              type="email"
              placeholder="Email"
              className="w-full"
            />
          </div>
          <InputField name="password" type="password" placeholder="Password" />
          <hr />
          <div className="flex gap-4">
            <InputField name="businessName" placeholder="Business name" />
            <SelectField
              name="businessCategory"
              placeholder="Business Category"
              showDefaultOption
              options={[
                {
                  label: 'Information Technology',
                  value: 'Information Technology',
                },
                { label: 'Marketing', value: 'Marketing' },
                { label: 'Consultancy', value: 'Consultancy' },
              ]}
            />
          </div>
          <InputField name="businessAddress" placeholder="Business address" />
          <Button>Start 14-day free trial</Button>
          <p className="text-base-frenchGray">or</p>
          <Button
            onClick={() => submitGoogleSignup()}
            type="button"
            kind="tertiary"
            className="border"
            leftNode={<GoogleLogo />}
          >
            Start 14-day free trial with Google
          </Button>
          <p className="text-base-frenchGray text-xs">
            By continuing, you agree to our Terms of Service and Privacy Policy
            .
          </p>
        </HookedForm>
      </div>
      <p className="mt-auto text-base-coolGray">
        Already have an account?
        <Link to="/login" className="text-brand-baseBlue">
          Log in
        </Link>
      </p>
    </div>
  );
};
