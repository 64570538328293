import { ReactNode, useEffect, useMemo } from "react";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { debounce } from "lodash";

interface FormProps<T extends FieldValues> {
  id?: string;
  onSubmit?: SubmitHandler<T>;
  onChange?: (v: T, name?: string) => void;
  defaultValues?: DefaultValues<T> | undefined;
  children: ReactNode;
  formHook?: UseFormReturn<T>;
  className?: string;
  mode?: Mode;
  changeDebounce?: number;
}

export const HookedForm = <T extends FieldValues = FieldValues>({
  id,
  formHook,
  onSubmit = () => null,
  onChange,
  defaultValues,
  children,
  mode = "onSubmit",
  className,
  changeDebounce,
}: FormProps<T>) => {
  const localHook = useForm<T>({
    defaultValues,
    mode,
  });
  const hook = formHook || localHook;

  const handleChange = useMemo(() => {
    if (!onChange) return;
    if (!changeDebounce) return onChange;
    return debounce(onChange, changeDebounce);
  }, [changeDebounce, onChange]);

  useEffect(() => {
    if (!handleChange) return;
    const subscription = hook.watch((v, { name }) =>
      handleChange(v as T, name),
    );
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hook.watch, handleChange]);

  return (
    <FormProvider<T> {...hook} key={id}>
      <form
        id={id}
        onSubmit={(event) => {
          event.stopPropagation();
          hook.handleSubmit(onSubmit)(event);
        }}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  );
};
