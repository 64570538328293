import { useState } from 'react';

export const useSettingsPage = () => {
  const [activeSubpage, setActivePage] = useState(1);

  const handleTabChange = (value: number) => {
    setActivePage(value);
  };
  return { activeSubpage, handleTabChange };
};
